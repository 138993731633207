
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: 'Roboto', sans-serif; 
  background-color: #fff; 
  margin: 0;
  padding: 0;
}

/* Add more styling if needed */
.child-text {
  font-size: 2rem !important;
}
